
import modalConstants from '~/store/modal/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import universeConstants from '~/store/universe/-constants'

export default {
  name: 'OrganismSpaceDetails',
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false
    },
    disableRequestOffer: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTab: 'residential',
      selectedAmenities: []
    }
  },
  computed: {
    locale() {
      return this.$i18n?.locale || 'en'
    },
    floorPlanDownloadActive() {
      return !!this.$store.state.base.meta.generalConfig.floorPlanDownloadActive
    },
    surfaceDetailsActive() {
      return !!this.$store.state.base.meta.generalConfig.surfaceDetailsActive
    },
    shortlistState() {
      return this.$store.getters?.getShortlist || false
    },
    tabData() {
      const tabData = []
      tabData.push({
        label: this.$t('residential'),
        tabId: 'residential'
      })
      tabData.push({
        label: this.$t('projectInfo'),
        tabId: 'project-info'
      })
      return tabData
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    project() {
      return this.$store.state.project.projects.find(
        (project) => project.slug === this.activeProject
      )
    },
    selectedSpace() {
      return Number(this.$route.params.space)
    },
    space() {
      let space = this.$store.state.base?.landlordDetails?.spaces?.find(
        (sp) => sp.id === this.selectedSpace
      ) || { floor: {} }
      space = {
        ...space,
        bedrooms: this.getSpaceInfoNumberByType(space.units, 'Room', '', space.number_of_rooms),
        balconies: this.getSpaceInfoNumberByType(
          space.units,
          'Balcony',
          'Balconies',
          space.has_balcony ? space.number_of_balconies : 0
        ),
        bathrooms: this.getSpaceInfoNumberByType(
          space.units,
          'Bathroom',
          '',
          space.number_of_bathrooms
        )
      }
      return space
    },
    cameraCoordinates() {
      const cameraCoordinates = this.space?.camera_coordinates || []
      return [...cameraCoordinates]?.sort((a, b) => a.order - b.order) || []
    },
    building() {
      return this.$store.state.base?.landlordDetails?.buildings?.find(
        (b) => b.id === this.space.building_id
      )
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    requestOfferSpaces() {
      return this.$store.state?.requestOffer?.spaces || []
    },
    currency() {
      return this.$store.state?.base?.meta?.generalConfig.baseCurrencySymbol || '€'
    },
    resourceUrl() {
      return `${this.$store.getters['base/cdnBase']}/static/${this.$config.CLIENT}/${this.project.slug}/${this.building.code}/${this.space.space_code}.pdf?amp;response-content-disposition=attachment`
    },
    amenities() {
      // OLD API
      // return this.$store.state.base?.landlordDetails?.amenities || []
      return this.spaceData?.amenities || []
    },
    sqmInfo() {
      // TODO: [Undo modifications if you have to calculate total_useful_area by including garden and balcony surfaces]
      // const balconySurface =
      //   this.space?.units?.find((u) => u.roomType.name.toLowerCase() === 'Balcony'.toLowerCase())
      //     ?.surface || 0
      // const gardenSurface =
      //   this.space?.units?.find((u) => u.roomType.name.toLowerCase() === 'Garden'.toLowerCase())
      //     ?.surface || 0
      return {
        // total_sqm: Number((this.space.constructed_sqm + balconySurface + gardenSurface).toFixed(2)),
        // useful_sqm: Number((this.space.util_sqm + balconySurface + gardenSurface).toFixed(2))
        total_sqm: Number(this.space.constructed_sqm.toFixed(2)),
        useful_sqm: Number(this.space.util_sqm.toFixed(2))
      }
    },
    generalConfig() {
      return this.$store?.state?.base?.meta?.generalConfig
    },
    priceInfo() {
      return this.generalConfig?.priceInfo?.[this.activeProject]?.text || ''
    }
  },
  methods: {
    mapAmenitiesTranslated(space) {
      // OLD API
      // const filteredAmenities =
      //   this.amenities?.filter((am1) => space.amenities.some((am2) => am1.id === am2.id)) || []
      // filteredAmenities = filteredAmenities?.map((am) => am.translation.label[this.locale]) || []
      // if (filteredAmenities.length === 0) return this.$t('No amenities found')
      // return filteredAmenities.join(', ')
      if (typeof space?.amenities !== 'undefined' && space.amenities.length === 0) {
        return this.$t('No amenities found')
      }
      // const amenities = space.amenities.map((am) => am.amenity)
      return space.amenities
        .map((amenity) => amenity.translation.find((am) => am.lang === this.locale).label)
        .join(', ')
    },
    openRequestOfferModal() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'space'
          }
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer',
        data: { space: { ...this.space, building: this.building } }
      })
      document.body.classList.add('disable-scroll')
    },
    addToShortlist() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_SHORTLIST_INFO_BOX),
        {
          open: true
        }
      )
      setTimeout(
        () =>
          this.$store.dispatch(
            universeConstants.withNamespace(universeConstants.action.TOGGLE_SHORTLIST_INFO_BOX),
            {
              open: false
            }
          ),
        5000
      )
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        {
          ...this.space,
          building: this.building,
          floor: this.space.floor
        }
      )
    },
    removeFromShortlist() {
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        {
          ...this.space,
          building: this.building,
          floor: this.space.floor
        }
      )
    },
    clearShortlist(space) {
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.CLEAR_ALL),
        null
      )
    },
    exploreSpace(space) {
      this.$router.push({
        path: `/project/${this.activeProject}/space/${space}`
      })
    },
    toggleDrawer() {
      this.$refs.drawer?.toggleFilter()
    },
    closeDrawer() {
      this.$refs.drawer?.close()
    },
    openDrawer() {
      this.$refs.drawer?.open()
    },
    handleExpandAvailability() {
      this.$emit('toggleAvailability')
    },
    getSpaceInfoNumberByType(units, key, customKey = '', defaultCount = 0) {
      let noBedrooms = 0
      if (!units || units.length === 0)
        return {
          text: key,
          value: defaultCount
        }
      noBedrooms = units.reduce((acc, obj) => {
        if (obj.title === key) return acc + 1
        return acc
      }, 0)

      let text = ''
      switch (noBedrooms) {
        case 0:
          text = key
          break
        case 1:
          text = key
          break
        default:
          if (customKey !== '') {
            text = customKey
          } else {
            text = `${key}s`
          }
      }
      return {
        text,
        value: noBedrooms || defaultCount
      }
    },
    getMappedFloorText(floor) {
      let text = ''
      // TODO: refactor this Petrichi fix :P
      switch (parseInt(floor, 10)) {
        case -3:
          text = `${this.$t('FloorRo')} -3<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case -2:
          text = `${this.$t('FloorRo')} -2<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case -1:
          text = `${this.$t('FloorRo')} -1<sup class="superior-text">${this.$t(
            'st'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 0:
          text = `${this.$t('Ground Floor')}`
          break
        case 1:
          text = `${this.$t('FloorRo')} 1<sup class="superior-text">${this.$t(
            'st'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 2:
          text = `${this.$t('FloorRo')} 2<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 3:
          text = `${this.$t('FloorRo')} 3<sup class="superior-text">${this.$t(
            'rd'
          )}</sup>  ${this.$t('Floor')}`
          break
        default:
          text = `${this.$t('FloorRo')} ${floor}<sup class="superior-text">${this.$t(
            'th'
          )}</sup>  ${this.$t('Floor')}`
      }
      return text
    },
    findAmenityTranslation(amenity) {
      const foundAmenityTranslation = amenity?.translation?.find(
        (translation) => translation.lang === this.locale
      )
      if (!foundAmenityTranslation) return ''
      return this.uppercaseText(foundAmenityTranslation.label)
    },
    uppercaseText(text) {
      return `${String(text).charAt(0).toUpperCase()}${String(text).slice(1)}`
    }
  }
}
